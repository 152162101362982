body {
  padding-top: 20px;
  background: url("./images/defocused.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.card {
  background-color: rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 4px 0px #000;
  -webkit-box-shadow: 0px 0px 4px 0px #000;
  box-shadow: 0px 0px 4px 0px #000;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
